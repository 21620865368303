@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


body * {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  color: #111;
}

body {
  background-image: url('./img/bg.jpg');
  background-size: cover;
}

.container a {
  color: #fff
}

.skills {
  margin-left: 1rem;
}
.skills li {
  margin-bottom: 0.25rem;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 700
}


.container {
  min-height: 100vh;
  max-width: 800px;
  padding: 1rem;
}

header h1 a {
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  text-transform: uppercase;
}

header p {
  font-size: 1.2rem;
  font-weight: 400;
}

header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
  
header div {
  display: flex;
  justify-content: space-between;
}

header ul {
  margin-top: 0.5rem;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}

ul {
}

header ul li {

  list-style-type: none;
}

header ul li a {
  margin-left: 1rem;
  list-style-type: none;
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  font-weight: 700
}

p {
  font-size: 1.2rem;
}

h2 {
  font-size: 1.2rem;
  margin-top: 0.25rem;
  font-weight: 400;
  text-align: left;
  color: #fff;
  font-style: italic;
}

article h2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.container article a {
  color: #111
}


h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 1);

}


.col {
  padding-right: 1rem;
}

.portfolio li {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}


.carrot-orange {
  background-color: rgba(243, 144, 1, 1);
}

.black {
  background-color: rgba(1, 1, 2, 1);
}

.ochre {
  background-color: rgba(196, 121, 1, 1);
}

.fulvous {
  background-color: rgba(229, 138, 0, 1);
}

.carrot-orange-2 {
  background-color: rgba(245, 144, 0, 1);
}

.tout {
  border: 3px solid #fff;
  padding: 0 1rem 1rem 1rem;
  border-radius: 1rem;
  background-color: #49A197;
  -webkit-box-shadow: 5px 5px 15px -1px rgba(0,0,0,0.24); 
box-shadow: 5px 5px 15px -1px rgba(0,0,0,0.24);;
}

.tout ul {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
/* Your existing styles */

.dropdown {
  display: inline-block;
}

.dropdown-header {
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.05rem;
}


.dropdown-list {
  list-style: none;
  margin: 0;
  position: absolute;
  top: 100%;
  left: -50px;
  padding: 0;
  border: 2px solid #fff;
  border-radius: 1rem;
  background-color: #49A197;
  padding: 0.5rem;
  -webkit-box-shadow: 5px 5px 15px -1px rgba(0,0,0,0.24); 
box-shadow: 5px 5px 15px -1px rgba(0,0,0,0.24);;
  display: none;
}

.dropdown-list li {
  list-style-type: none;
  padding: 0.5rem;
  margin-left: -1rem;
  cursor: pointer;
}

/* Add this style to the existing CSS */
.dropdown-list {
  display: block;
}
.dropdown-list p {
  color: #fff;
  font-size: 0.9rem;
  margin-bottom: 0;
  margin-left: 1rem
}
.tout ul li {
  list-style-type: none;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.25rem;
  margin-bottom: 0;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #fff;
  margin-right: 1rem;
}

.tout ul {
  margin: 0;
}

.tout-text {
  margin-bottom: 1rem;
  font-weight: 700;
}

.double {
  display: flex;
  flex-direction: row
}


.icon {
  width: 90%;
  margin: 0.25rem;
}

.tout p {
  font-size: 1.4rem;
  
}

.tout-text {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.contain {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
}

.cell {
  width: 40px;
  height: 40px;
  border: 1px solid #999;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
}

h4 {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem
}

h5 {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1rem
}

.projects a {
  color: #111
}

.projects ul, .portfolio-projects {
  margin: 1rem 0 1rem 1rem;
  font-size: 1.2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two equal-sized columns */
  grid-gap: 1rem;
  /* Adjust the gap between grid items */
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Two equal-sized columns */
  grid-gap: 1rem;
  /* Adjust the gap between grid items */
  margin-bottom: 1rem;
}

.skills-container li {
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  margin-left: 1rem;
  margin-bottom: -1rem;
  padding-bottom: 0;
}
.blog-list {
  margin: 0;
}

.blog-list img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.blog-list h2 {
  font-weight: 700;
}

.blog-list{
  list-style-type: none;
}
.blog img {
  width: 100%;
  height: auto;
  margin-bottom: 1rem;}
footer {
  margin-top: 2rem;
}

.lead {
  font-size: 1.4rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  font-family: "Merriweather", serif;
}

.lead a {
  font-weight: 700;
  font-family: "Merriweather", serif;
}

.lead-holder {
  margin: 0 -0.5rem 2rem -0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: rgba(213, 111, 8, 1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgb(213, 111, 8);
  background: linear-gradient(54deg, rgba(213, 111, 8, 1) 36%, rgba(243, 144, 1, 1) 100%);
}
.message {
  text-align: center;
  color: #fff;
  padding: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background-color: rgba(213, 111, 8, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background: rgb(213, 111, 8);
  background: linear-gradient(54deg, rgba(213, 111, 8, 1) 36%, rgba(243, 144, 1, 1) 100%);
}

.message * {
  color: #fff;
}

.skills-holder {

  margin: 2rem -0.5rem 2rem -0.5rem;
  padding: 0.5em 1.5rem;
  border-radius: 0.5rem;
  background-color: rgba(213, 111, 8, 1);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background: rgb(213, 111, 8);
  background: linear-gradient(54deg, rgba(213, 111, 8, 1) 36%, rgba(243, 144, 1, 1) 100%);
}
.plural-list {
  margin-left: 1rem;
}
.plural-list li a {
  margin-bottom: 1rem;
  color: #111;
}

.list-group {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

article ul {
  margin: 1rem;
}

article ul li {
  font-size: 1.2rem;
}

article p {
  margin-bottom: 1rem;
}

pre, code {
  display: block;
  font-family: "Fira Mono", monospace;
  font-size: 1rem;
  background-color: #eee;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0;
  white-space: pre-wrap;
  overflow-x: auto;
}

.youtube {
  margin: 1rem 0;
}

.full-img {
  width: 100%;
  margin-bottom: 1rem;
}

.half-img {
  width: 50%;
}

.projects {
  width: 100%;
  padding: 2rem;
  border: 1px solid #111;
  border-left: 3px solid #111;
  background-color: rgba(255, 255, 255, 0.95);
  transition: 0.5s;
  border-radius: 0.5rem;

}
/* .projects:hover {
  transform: scale(1.05);
} */

article {
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}

article * {
  color: #111
}

article h2 {
  font-size: 1.8rem;
}

.bold {
  font-weight: 700;
  text-transform: uppercase;
}

.profile {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 2px solid #fff;
  margin-right: 0.5rem;
  margin-left: 2rem;
}

.italic {
  font-style: italic;
}

.api {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1rem;
}

input[type=text] {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%
}

@media only screen and (max-width: 500px) {

  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    /* Two equal-sized columns */
    grid-gap: 1rem;
    /* Adjust the gap between grid items */
  }

  .lead-holder {

    flex-direction: column;
  }

  .profile {
    display: none;
  }

  header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  header ul {
    margin: 1rem 0 0 -0.5rem;
  }

  header ul li {
    margin: 0 0.5rem;
  }

  header h1,
  header h2 {
    text-align: center
  }
  .tout-text {
    width: 100%;
  }
  .double {
    flex-direction: column
  }
  .lead-holder {
    margin: -0.5rem;
  }
  header ul li a {
    margin: 0 0.25rem;
    font-size: 0.8rem
  }
}

.messages div {
  border-bottom: 1px solid #999;
  margin-bottom: 1rem;
}

.projects-list p span, .skilllist span {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 0.5rem;
  text-decoration: underline;
}
.projects-list {
  margin: 0
}
.projects-list li {
  list-style-type: none;
  border-top: 1px solid #999;
  padding: 2rem 0
}
.projects-list p span:hover {
  cursor: pointer;
}
.projects-list li ul li {
  list-style-type: disc;
  font-size: 1rem;
  border: none;
  padding: 0
}

button {
  border: 1px solid #999;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  margin-bottom: 1rem
}
.skilllist {
  margin-bottom: 0rem;
}

.monetized {
  background-color: #49A197;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  color: #fff;
}